import React from 'react'
import sanitizeHtml from 'sanitize-html';
import {useStaticQuery, graphql} from "gatsby"
import Layout from '../components/layout';
import {Helmet} from "react-helmet"
import {navigate} from "@reach/router";
import sanitizeOptions from "../helper/sanitizeOptions";

export default () => {
    const sanitizeOpt = sanitizeOptions();
    const data = useStaticQuery(graphql`
        {
            noroutePage: wpPage(slug: {eq: "noroute-page"}) {
                title
                content
                noroutePageCustomFields {
                      goBackButton
                }
                seo {
                    title
                    metaDesc
                    canonical
                }
                additionalMetaTag {
                    keywords
                    ogDescription
                    ogTitle
                    ogType
                    ogImage {
                        mediaItemUrl
                    }
                    twitterImage{
                        mediaItemUrl
                    }
                    twitterTitle
                    twitterDescription
                }
            }
        }
    `);

    const {
        noroutePage
    } = data;

    const {
        content,
        noroutePageCustomFields,
        title,
        seo,
        additionalMetaTag
    } = noroutePage;

    // Set metadata
    const seoData = {...{seo}, additionalMetaTag, title};

    const goBack = () => {
        navigate(-1);
    };

    // 'dark-background' there is option for dark background
    // property={'disablePreFooter'} it's for disable pre-footer

    return (
        <Layout
            seoData={seoData}>
            <div property={'disablePreFooter'}
                 className={'no-route'}>
                <Helmet
                    bodyAttributes={{
                        class: 'dark-background no-route-page'
                    }}
                />
                <div className={'page-content inner-pages'}>
                    {!!content && (
                        <div className={'content title-large'}
                             dangerouslySetInnerHTML={{__html: sanitizeHtml(content, sanitizeOpt.contentData)}}/>
                    )}
                    {!!noroutePageCustomFields?.goBackButton && (
                        <button className={'transition-link mc-link mc-button-secondary top-left'}
                                onClick={goBack}>
                        <span className="label">
                            {noroutePageCustomFields.goBackButton}
                        </span>
                        </button>
                    )}
                </div>
            </div>
        </Layout>
    )
}